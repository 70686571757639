(function(d, active, breakpoint){
	"use strict";

	// helper functions to toggle a class name on an element
	function removeClass(el, classname){
		el.className = el.className.replace(" "+classname, "");
	}
	
	function addClass(el, classname){
		el.className+= " "+classname;
	}
    
    function hasClass(el, classname){
        return ( (" " + el.className + " ").replace(/[\t\r\n]/g, " ").indexOf(" "+classname+" ") >= 0 );

    }
	
	function toggleClass(el, classname){
		if( hasClass(el, classname) ){
			removeClass(el, classname);
		} else {
			addClass(el, classname);
		}		
	}

	//toggle the main menu
	var menuButton = d.getElementById("logo");
	var menu = d.getElementById("globalNav");

	menuButton.onclick=function(event){
        if(window.innerWidth < breakpoint){
            event.preventDefault ? event.preventDefault() : (event.returnValue = false);
        
            //set the resoures nav as inactive
            removeClass(resources, active);
            removeClass(resourcesButton, active);

            //toggle the main menu
            toggleClass(menu, active);
            toggleClass(menuButton, active);
            
            return false;
        }  
	};

    var liarray = menu.querySelectorAll("nav > ul > li");
    var veil = d.getElementById("headerMenuVeil");
   
    function showveil(){
        addClass(veil, active);
    }
    
    function hideveil(){
        removeClass(veil, active);
    }

    for (var index = 0; index < liarray.length; index++) {
        var element = liarray[index];
        
        if( element.querySelector(".subNav") ){
            if (element.addEventListener) {                    // For all major browsers, except IE 8 and earlier
                element.addEventListener("mouseover", showveil);
                element.addEventListener("mouseout", hideveil);                
            } else if (element.attachEvent) {                  // For IE 8 and earlier versions
                element.attachEvent("mouseover", showveil);
                element.attachEvent("mouseout", hideveil);
            }            
        }
    }
    
	//toggle the resources menu
	var resourcesButton = d.getElementById("resourcesButton");
	var resources = d.getElementById("resources");

	resourcesButton.onclick=function(){
		//set the main nav as inactive
		removeClass(menu, active);
		removeClass(menuButton, active);
        
        //hide the search
        removeClass(search, active);
        searchInput.blur();

		//toggle the resource nav
		toggleClass(resources, active);
		toggleClass(resourcesButton, active);
	};
	
    //toggle the search when the display is over 1024px wide
    var searchButton = d.getElementById("searchButton");
	var searchInput = d.getElementById("searchInput");
	var search = d.getElementById("siteSearch");

	searchButton.onclick=function(){
        var width = window.innerWidth || d.documentElement.clientWidth || d.body.clientWidth;
        console.log("click", (width > breakpoint));
        if(width > breakpoint){
            if( hasClass(search, active) ){
                //search is active, close the form
                removeClass(search, active);
                
                searchInput.blur();
                                
            } else {
                //search is inactive, open the form
                
                //set the resources nav as inactive
                removeClass(menu, active);
                removeClass(menuButton, active);		

                //toggle the resource nav
                toggleClass(search, active);
                
                searchInput.focus();                
            }

        }          

	};
    
    //toggle the page menu on mobile & get its height
	var pageNavButton = d.getElementById("pageNavButton");
	var pageNav = d.getElementById("pageNav");

    // try to grab the height of the elem
    var pageNavClone = pageNav.cloneNode(true);
    pageNavClone.style.visibility = "hidden";
    pageNavClone.style.maxHeight = "100%";
    pageNav.parentElement.appendChild(pageNavClone);
    
    pageNav.dataset.height = pageNavClone.clientHeight; 
    pageNav.parentElement.removeChild(pageNavClone);

	//toggle the resources menu
	pageNavButton.onclick=function(){
		toggleClass(pageNav, active);
		toggleClass(pageNavButton, active);
        pageNav.style.maxHeight = 0;
        if( hasClass(pageNav, active) ){
               pageNav.style.maxHeight = pageNav.dataset.height + "px";
        }
	};    
    
    //if the browser does not support svg we need to active any images instead of svgs.
    if (Modernizr && !Modernizr.svg) {
        //the logo in the header
        var headerLogo = d.getElementById("headerlogoimage");
        headerLogo.setAttribute('src', headerLogo.getAttribute("data-src"));
        
        //the logo in the footer
        var footerLogo = d.getElementById("footerlogoimage");
        footerLogo.setAttribute('src', footerLogo.getAttribute("data-src"));
        
    }
    
    // catch any hits to links that should be passed to google analytics. 
    var hbrun = false; // tracker has not yet run
    var hblink = false;
    
    /* HitCallback to open link in same window after tracker */
    var hitBack = function() {
        /* run once only */
        if (hbrun) return;
        hbrun = true;
        window.location.href = hblink;
    };
    function trackClick(event){
        var label = this.getAttribute('data-track-label');
        var category = this.getAttribute('data-track-category');
        if (category === undefined) category = 'Static';

        hblink = event.target.href;

        if(typeof ga !== undefined){
            ga('send', 'event', {
                'eventCategory': category, //required
                'eventAction': 'click', //required
                'eventLabel': label,
                'hitCallback': hitBack,
                'hitCallbackFail' : hitBack
            });

            setTimeout(hitBack, 1000);

            event.preventDefault ? event.preventDefault() : event.returnValue = !1;
            return false;
        }        
    }
    
    var atags = d.querySelectorAll("a.track");

    for (var index = 0; index < atags.length; index++) {
        if (atags[index].addEventListener) {                    // For all major browsers, except IE 8 and earlier
            atags[index].addEventListener("click", trackClick);
        } else if (atags[index].attachEvent) {                  // For IE 8 and earlier versions
            atags[index].attachEvent("click", trackClick);
        }  
    }
    // Service worker to cache assets
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('service-worker.js', {
        });
    }
    
}(document, "active", 1024));